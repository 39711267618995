.pinterest-for-woocommerce-image-wrapper {
  left: 10px;
  position: absolute;
  top: 10px;
  z-index: 50;
  opacity: 0;
  visibility: hidden; }
  @media (hover: none) {
    .pinterest-for-woocommerce-image-wrapper {
      opacity: 1;
      visibility: visible; } }
  .product:hover > .pinterest-for-woocommerce-image-wrapper,
  .wc-block-grid__product:hover > .pinterest-for-woocommerce-image-wrapper {
    opacity: 1;
    visibility: visible; }
    .product:hover > .pinterest-for-woocommerce-image-wrapper a,
    .wc-block-grid__product:hover > .pinterest-for-woocommerce-image-wrapper a {
      text-decoration: none; }

.wp-block-post.product,
.wc-block-product.product {
  position: relative; }

/*# sourceMappingURL=../../source/_maps/css/frontend/pinterest-for-woocommerce-pins.css.map */
